/**
 Export all getters from state
*/

const MAP_SERIES = (series) => {
  if (series.length === 0) {
    series.push(0);
  }

  if (series.length === 1) {
    series.push(0);
  }

  return series.map((v, i) => ({ x: i, y: parseFloat(v) }));
};

export const getClusterAndShareData = ({ cluster_and_share }) =>
  cluster_and_share || {};

export const getClusterPartnerInfo = ({ cluster_partner_info }) => {
  const result =
    Object.keys(cluster_partner_info)
      .filter((key) => key !== "company_bio")
      .map((key, index, array) => {
        const {
          description,
          icon,
          short_explain,
          init_billing_range,
          end_billing_range,
          percent,
          color_background_icon,
          text_color_icon,
        } = cluster_partner_info[key];

        return {
          description,
          icon,
          short_explain,
          color_background_icon,
          text_color_icon,
          init_billing_range,
          end_billing_range,
          percent: percent / 100,
          is_block: !(
            index === 0 ||
            (index > 0 &&
              cluster_partner_info[array[index - 1]].percent === 100)
          ),
        };
      }) || [];

  return result;
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const getVoucher = (state) => {
  if (Object.keys(state.overview).length)
    return state.overview.vouchers[0] || {};
  else return {};
};

export const getHasScripts = ({ has_scripts }) => has_scripts;

/**
 *
 * @param {*} param0
 * @returns
 */
export const getInvites = ({ invites }) => invites;

/**
 * @param show_up
 * @return {{}}
 */
export const getShowUp = ({ show_up }) => show_up;

/**
 * @param reward_ranking
 * @return {{}}
 */
export const getRewardRanking = ({ reward_ranking }) => {
  return reward_ranking || [];
};

/**
 * @param layout
 * @return {{}}
 */
export const getDashboardMode = ({ layout }) => {
  return layout.mode || "commission";
};

/**
 * @param show_up_watched
 * @return {boolean}
 */
export const isShowUpWatched = ({ show_up_watched }) => {
  return !!show_up_watched;
};

/**
 * @param order_totals
 * @param on_request
 * @return {null|{series, value}}
 */
export const getTotalOrderQuantity = ({ order_totals, on_request }) => {
  let value = 0,
    details = { current: 0, past: 0, percent: 0 },
    series = MAP_SERIES([]),
    feeling = "Z";

  if (order_totals) {
    let past = order_totals.range.past.data[0].quantity,
      current = order_totals.range.current.data[0].quantity,
      percent = ((current - past) / (current || 1)) * 100;
    details.current = current;
    details.percent = percent;
    details.past = past;
    feeling = order_totals.feeling;
    value = order_totals.value.quantity;
    series = MAP_SERIES(order_totals.series[0].quantity);
  }

  return {
    on_request: on_request,
    title: "dashboard.order_quantity",
    link: "/sales",
    tooltip_message: "",
    icon: "dashboard.icons.order_quantity",
    value: value,
    series: series,
    details: details,
    feeling: feeling,
    type: "number",
  };
};

export const showButtonByAlcadas = (alcadas, routeName) =>
  Object.values(alcadas).includes(routeName);

/**
 *
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetter
 */
export const rewardWidgets = (state, getters, rootState, rootGetter) => {
  // Obtém parâmetros de "account/getParams" ou define como objeto vazio
  const params = rootGetter["account/getParams"] || {};

  // Configuração base para cada widget
  const baseConf = {
    on_request: state.on_request,
    tooltip_message: "",
    series: [{ x: 0, y: 0 }],
    details: { current: 0, past: 0, percent: 0 },
    feeling: "Z", // Um estado de "sentimento" padrão, não especificado
  };

  // Desestruturação dos dados do estado para facilitar o acesso
  const {
    reward_total_sales = 0,
    reward_total_orders = 0,
    points_sumary: {
      points_received = 0,
      points_to_be_released = 0,
      points_rescued = 0,
      balance = 0,
      points_expired = 0,
      points_expire_soon = 0,
    } = {},
  } = state;

  // Controle padrão para os widgets, caso os parâmetros não estejam definidos
  const defaultWidgetsControl = {
    dash_card_tot_sales: { show: 0 },
    dash_card_credits_received: { show: 0 },
    dash_card_credits_redeem: { show: 0 },
    dash_card_available: { show: 0 },
  };

  // Obtém o controle dos widgets dos parâmetros ou usa o padrão
  const widgetsControl = Object.fromEntries(
    Object.entries(params.dash_widgets?.value || defaultWidgetsControl).filter(
      ([, item]) => item && item.show == 1 // Filtra apenas os widgets com "show" ativo
    )
  );

  // Função auxiliar para criar um widget com base em parâmetros
  const createWidget = (key, icon, value, extra = {}) => {
    const widget = widgetsControl[key];
    return {
      ...baseConf, // Configurações base
      title: widget.title,
      icon,
      value,
      tooltip_message: widget.tooltip_message,
      button_title: widget.button_title,
      button_icon: widget.button_icon,
      link: widget.link || "",
      show: widget.show, // Mostra o widget ou não
      ...extra, // Adiciona qualquer dado extra específico do widget
    };
  };

  // Mapeia os widgets com base nas chaves definidas em "widgetsControl"
  const data = Object.keys(widgetsControl).map((key) => {
    switch (key) {
      case "dash_card_tot_sales":
        return createWidget(key, "total_sales.png", reward_total_sales, {
          total_orders: reward_total_orders,
        });
      case "dash_card_credits_received":
        return createWidget(key, "available.png", points_received, {
          credits_under_analysis: points_to_be_released,
        });
      case "dash_card_credits_redeem":
        return createWidget(key, "rescued.png", points_rescued);
      case "dash_card_available":
        return createWidget(key, "total.png", balance);
      default:
        return null; // Ignora chaves desconhecidas
    }
  });

  // Se há pontos expirados ou a expirar em breve, adiciona um widget extra
  if (points_expired || points_expire_soon) {
    data.push({
      ...baseConf,
      title: "dashboard.reward_points_expired",
      icon: "expired.png",
      identifier: "points_expired_or_expires",
      classes: ["widget-points-expired-or-expires"],
      value: points_expired,
      sub_label: "(nos próximos 3 meses)",
      reward_points_expired_soon: points_expire_soon,
      type: "number",
      tooltip_message: "dashboard.tooltip_message.reward_points_expired",
      button_title: "",
      button_icon: "",
    });
  }

  return data;
};

/**
 * @param order_totals
 * @param on_request
 * @return {null|{series, value}}
 */
export const getTotalOrderSum = ({ order_totals, on_request }) => {
  let value = 0,
    details = { current: 0, past: 0, percent: 0 },
    series = MAP_SERIES([]),
    feeling = "Z";

  if (order_totals) {
    let past = parseFloat(order_totals.range.past.data[1].sum_order),
      current = parseFloat(order_totals.range.current.data[1].sum_order),
      percent = ((current - past) / (current || 1)) * 100;
    details.current = current;
    details.past = past;
    details.percent = percent;
    feeling = order_totals.feeling;
    value = parseFloat(order_totals.value.sum_order);
    series = MAP_SERIES(order_totals.series[0].sum_order);
  }

  return {
    on_request: on_request,
    title: "dashboard.order_sum",
    link: "/sales",
    tooltip_message: "",
    value: value,
    series: series,
    details: details,
    feeling: feeling,
    type: "money",
  };
};

export const getTotalOrderDash = ({ order_totals }) => {
  return order_totals || {};
};

/**
 * get visitors data
 * @param {*} visitors_data
 * @returns
 */
export const getVisitorsData = ({ visitors_data }) => {
  const getPercent = (v1, v2) => {
    let percentValue = (v1 * 100) / v2;
    return v1 < v2 && v1 != 0
      ? 100 - percentValue
      : v1 > v2 && v2 != 0
      ? percentValue - 100
      : 0;
  };

  if (visitors_data) {
    let newValue = visitors_data.value;
    let OldValue = visitors_data.range.past.data[0].quantity;
    return {
      value: newValue,
      percent: getPercent(newValue, OldValue),
      series: visitors_data.series[0].quantity,
      up_down_value: newValue >= OldValue ? true : false,
      up_down_color: newValue >= OldValue ? "#008000" : "#ff0000",
    };
  }

  return {
    value: 0,
    percent: 0,
    series: [],
    up_down_value: true,
    up_down_color: "#8ebce4",
  };
};

/**
 * get visitors data
 * @param {*} visitors_data
 * @returns
 */
export const getVisitorsData2 = ({ visitors_data }) =>
  visitors_data.range.past || 0;

/**
 * @param visitors_totals
 * @param on_request
 * @return {null|{series, value}}
 */
export const getTotalVisitors = ({ visitors_totals, on_request }) => {
  let value = 0,
    details = { current: 0, past: 0, percent: 0 },
    series = MAP_SERIES([]),
    feeling = "Z";

  if (visitors_totals) {
    let past = visitors_totals.range.past.data[0].quantity,
      current = visitors_totals.range.current.data[0].quantity,
      percent = ((current - past) / (current || 1)) * 100;
    details.current = current;
    details.percent = percent;
    details.past = past;
    feeling = visitors_totals.feeling;
    value = visitors_totals.value;
    series = MAP_SERIES(visitors_totals.series[0].quantity);
  }

  return {
    on_request: on_request,
    title: "dashboard.visitors",
    link: "",
    tooltip_message: "",
    value: value,
    series: series,
    details: details,
    feeling: feeling,
    type: "number",
  };
};

export const getWidgets = (state, getters) => {
  return getters.rewardWidgets;
};

/**
 * @param visitor_graph
 * @return {*[]|*}
 */
export const getVisitantsGraphData = ({ visitor_graph }) => {
  if (visitor_graph) {
    let dt = visitor_graph.map((v) => parseFloat(v.total));
    if (15 - dt.length >= 1) {
      while (15 - dt.length >= 1) {
        dt = [0, ...dt];
      }
    }
    return dt;
  }

  return [];
};

/**
 * @param reward_points_range
 * @return {*[]|*}
 */
export const getRewardPointGraphData = ({ reward_points_range }) => {
  if (reward_points_range) {
    let dt = reward_points_range.map((v) => parseFloat(v.total));
    if (15 - dt.length >= 1) {
      while (15 - dt.length >= 1) {
        dt = [0, ...dt];
      }
    }
    return dt;
  }

  return [];
};

/**
 * @param on_request
 */
export const isOnRequest = ({ on_request }) => on_request;

/**
 * @param ecomm
 */
export const getEcomm = ({ ecomm }) => ecomm;

export const getSpread = ({ spread }) => spread;

export const hasSpread = ({ spread }) => {
  return (
    Object.values(spread || {}).filter((sp) => sp.value && sp.value.length)
      .length > 0
  );
};

export const getPartnerId = (state, getters, rootState, rootGetter) =>
  rootGetter["auth/userPartnerId"];

export const getRewardPoints = ({ reward_points_balance }) =>
  reward_points_balance || 0;

export const getBanner = (state) => state.banner || {};

export const getDocsPendingDash = (state) => {
  if (Object.keys(state.doc_pending_dash).length) {
    const totalDone =
      state.doc_pending_dash.total - state.doc_pending_dash.totalPending;

    const percent =
      state.doc_pending_dash.totalPending > 0
        ? (totalDone * 100) / state.doc_pending_dash.total
        : 100;

    const documents = transformObjectToArray(
      state.doc_pending_dash.categoryPending
    );

    const data = {
      percent: percent.toFixed(0),
      docs: documents.map((doc) => ({ description: doc })),
    };

    return data;
  }

  return {
    percent: 0,
    docs: [],
  };
};

export const getPointsSummary = (state) => state.points_sumary || {};

/**
 * receive objects like { key1: "v1, v2", key2: "v3, v4" } and return an array like [v1, v2, v3, v4]
 */
function transformObjectToArray(obj) {
  const keys = Object.keys(obj),
    valuesToExtract = keys.map((key) => obj[key]);

  const newArray = valuesToExtract
    .join(", ")
    .split(", ")
    .filter((value) => value.trim())
    .map((value) => value.trim());

  return newArray;
}

export const getFlipThumb = (state) => state.flip_thumb[0] || { value: false };

export const getDashImage = (state) => state.dash_image || [];

const formatVoucherDescription = (value, type) => {
  if (type === "percent") {
    return `${value}% OFF`;
  }
  return `R$ ${value} OFF`;
};

const capitalize = (str) => str && str.charAt(0).toUpperCase() + str.slice(1);

// UNIFIED GETTERS - dashboard centralizado

export const getOverview = (state) => state.overview;

export const getCompaniesList = (state) => {
  if (Object.keys(state.overview).length)
    return state.overview.listCompaniesAvailableForRegister || [];
  else return [];
};

export const getCompaniesRegisteredList = (state) => {
  if (Object.keys(state.overview).length)
    return state.overview.listCompaniesPartnerRegistered || [];
  else return [];
};

export const getCompaniesRegisteredSelect = (state) => {
  if (Object.keys(state.overview).length)
    return (
      state.overview.listCompaniesPartnerRegistered
        .filter((e) => e.slug !== "flipnet")
        .map((e) => ({
          id: e.id,
          image: e.logo_foto_link,
          label: capitalize(e.nome),
        })) || []
    );
  else return [];
};

export const getAllLinks = (state) => {
  if (Object.keys(state.overview).length)
    return (
      state.overview.linkStores.map((e) => ({
        link_store: e.link_store,
        link_company_image: e.link_company_image,
        parent_name: capitalize(e.parent_name),
      })) || []
    );
  return [];
};

export const getInRequestsDash = (state) => state.in_requests_dash;

export const getNotAssociatedAccs = (state) => {
  if (Object.keys(state.overview).length)
    return state.overview.listNotAssociatePartnerAccount || [];
  else return [];
};

export const getAllVouchers = (state) => {
  if (Object.keys(state.overview).length)
    return (
      state.overview.vouchers.map((e) => ({
        ...e,
        label: `${capitalize(
          e.parceiro.friendly_name
        )} - ${formatVoucherDescription(e.discount, e.type_discount)} | CÓD: ${
          e.number
        }`,
      })) || []
    );
  else return [];
};
