/**
 * all states from store
 */
export default {
  productsByCategories: [],
  influ_data: {},
  company_details: {},
  sidebar: false,
  all_products: []
};
