import { sanitizedPayloadProducts } from "../functions/sanitizedPayloadProducts";
import * as types from "./mutations-types";
import services from "@/shared/http";


export const setLoading = ({ commit }, payload) => {
    commit(types.SET_LOADING, payload);
};

export const setLoadingAddingOneProduct = ({ commit }, payload) => {
    commit(types.SET_LOADING_ADDING_ONE_PRODUCT, payload);
};

export const setResearchingProduct = ({ commit }, payload) => {
    commit(types.SET_RESEARCHING_PRODUCT, payload);
};

export const setDrawer = ({ commit }, payload) => {
    commit(types.SET_DRAWER, payload);
};

export const setFetchingFilters = ({ commit }, payload) => {
    commit(types.SET_FETCHING_FILTERS, payload);
};

export const setCurrentPage = ({ commit }, payload) => {
    commit(types.SET_CURRENT_PAGE, payload);
};

export const setProducts = ({ commit }, payload) => {
    commit(types.SET_PRODUCTS, payload);
};

export const setSelectedAllProducts = ({ commit }, payload) => {
    commit(types.SET_SELECTED_ALL_PRODUCTS, payload);
}

export const setFilters = ({ commit }, payload) => {
    commit(types.SET_FILTERS, payload);
};

export const setPriceRange = ({ commit }, payload) => {
    commit(types.SET_PRICE_RANGE, payload);
};

export const setPartnerLandingLink = ({ commit }, payload) => {
    commit(types.SET_PARTNER_LANDING_LINK, payload);
};

export const setParentsIDs = ({ commit }, payload) => {
    commit(types.SET_PARENTS_ID, payload);
};

export const setInfluencerID = ({ commit }, payload) => {
    commit(types.SET_INFLUENCER_ID, payload);
};

export const setFilterLikes = ({ commit }, payload) => {
    commit(types.SET_FILTER_LIKES, payload);
};

export const setFilterKeys = ({ commit }, payload) => {

    const updatedPayload = Object.assign({}, payload);

    // Verifica se 'age' tem um valor e copia para 'age_group'
    if (payload.age) {
        updatedPayload.age_group = payload.age;
    }

    if (payload.brands) {
        updatedPayload.brand_id = payload.brands
    }

    delete updatedPayload.brands
    delete updatedPayload.categories
    delete updatedPayload.age;

    if (!updatedPayload.gender || updatedPayload.gender.length === 0) {
        delete updatedPayload.gender;
    }

    if (!updatedPayload.brand_id || updatedPayload.brand_id.length === 0) {
        delete updatedPayload.brand_id;
    }

    if (!updatedPayload.age_group || updatedPayload.age_group?.length === 0) {
        delete updatedPayload.age_group
    }

    // Realiza o commit com o payload atualizado
    commit(types.SET_FILTER_KEYS, updatedPayload);
};

export const setIndividualFilters = ({ commit }, payload) => {

    const updatedPayload = Object.assign({}, payload);

    Object.keys(updatedPayload).forEach((key) => {
        // Se a chave não tem valor ou está vazia, remove a chave
        if (!updatedPayload[key] || updatedPayload[key].length === 0) {
            delete updatedPayload[key];
        }
    });

    delete updatedPayload.brands;

    commit(types.SET_SELECTED_FILTERS, updatedPayload);
}

export const setOrderBy = ({ commit }, payload) => {
    commit(types.SET_ORDER_BY, payload);
};

export const setPayloadProducts = ({ commit }, payload) => {
    commit(types.SET_PAYLOAD_PRODUCTS, payload);
}

export const setTextSearch = ({ commit }, payload) => {
    commit(types.SET_SEARCH_TEXT, payload);
}

export const resetFiltersPayloads = ({ commit }) => {
    commit(types.SET_SEARCH_TEXT, '');
    commit(types.SET_SELECTED_FILTERS, {});
    commit(types.SET_FILTER_LIKES, {});
    commit(types.SET_FILTER_KEYS, {});
    commit(types.SET_ORDER_BY, {});
    commit(types.SET_CURRENT_PAGE, 1);
    commit(types.SET_SELECTED_ALL_PRODUCTS, []);
    commit(types.SET_LOADING, false);
    commit(types.SET_RESEARCHING_PRODUCT, false);
    commit(types.SET_PRICE_RANGE, {});
}

export const fetchFilters = ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        services.my_lists
            .getFilters({}, payload)
            .then((data) => {
                dispatch("setFilters", data);
                resolve();
            })
            .catch((error) => reject(error));
    });
};

export const fetchProductsAndFilters = async ({ state, dispatch }) => {
    const { 
        text_search, selectedFilters, parent_ids, filter_likes, order_by, current_page,
        influencer_id, filter_keys, researching_product, filter_price_range, is_fetching_filters
    } = state;

    
    const updatedPayloadProducts = {
        ...selectedFilters,
        parent_ids: parent_ids,
        partner_ids: influencer_id,
        filter_likes: filter_likes,
        filter_keys: filter_keys,
        price_range: filter_price_range,
        order_by: order_by,
        page: current_page,
        favorites: 0,
    };

    const payload = sanitizedPayloadProducts(updatedPayloadProducts)

    await fetchProducts({ dispatch }, payload)

    // Só busca os filtros quando o usuário usar o input 'Busque por produtos'
    // researching_product: é o loading (true) enquanto busca os produtos após usar 'Busque por produtos'
    if ( researching_product || is_fetching_filters ) {
        const payloadGetFilters = {
            parent_id: parent_ids[0],
            params: `?prod=${text_search}`
        }

        await fetchFilters({ dispatch }, payloadGetFilters)
    }
}

export const fetchProducts = ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        services.my_lists
            .getProducts(payload)
            .then((data) => {
                dispatch("setProducts", data);
                resolve();
            })
            .catch((error) => reject(error));
    });
};


export const fetchPartnerLandingLink = async ({ dispatch }) => {
    const output = await services.my_lists.getPartnerLandingLink()

    dispatch("setPartnerLandingLink", output);
}

