export default {
    filters: [],
    products: [],
    payloadProducts: {},
    text_search: '',
    is_fetching_filters: false, // É pra buscar novamente os filtros no endpoint?
    parent_ids: [],
    influencer_id: [],
    filter_likes: {},
    filter_keys: {},
    filter_price_range: {},
    order_by: {},
    selectedFilters: {},
    select_all_products: [],
    current_page: 1,
    drawer: false,
    researching_product: false,
    loading_adding_one_product: {
        status: false,
        activeOperationsCount: 0
    },
    loading_searching: false,
    partner_landing_link: null
}