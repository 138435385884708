import * as types from "./mutation-types";
import services from "@/shared/http";

/**
 * @param commit
 * @return {*}
 */
export const setInfluProductsByCategories = ({ commit }, payload) => {
  commit(types.SET_INFLU_PRODUCTS_BY_CATEGORIES, payload);

  const allProducts = payload.flatMap(category => category.prods_parent);

  commit(types.SET_ALL_PRODUCTS, allProducts);
};

/**
 * @param commit
 * @return {*}
 */
export const setInfluEcomCustomization = ({ commit }, payload) => {
  commit(types.SET_INFLU_DATA, payload);
};

/**
 * @param commit
 * @return {*}
 */
export const setCompanieDetails = ({ commit }, payload) => {
  commit(types.SET_COMPANIE_DETAILS, payload);
};

/**
 * @param commit
 * @return {*}
 */
export const setSidebarStatus = ({ commit }, payload) => {
  commit(types.SET_SIDEBAR_STATUS, payload);
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchInfluProductsByCategories = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.public_landingpage
      .getInfluProducts({}, payload)
      .then((response) => {
        dispatch("setInfluProductsByCategories", response.data);
        resolve();
      })
      .catch((error) => {
        reject(error);
        window.location.href = "/404";
      });
  });
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchInfluEcomCustomization = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.public_landingpage
      .getEcommCustomization({}, payload)
      .then((response) => {
        dispatch("setInfluEcomCustomization", response);
        resolve();
      })
      .catch((error) => {
        reject(error);
        window.location.href = "/404";
      });
  });
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchCompanieDetails = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.partner
      .parentBySlug(payload)
      .then((response) => {
        dispatch("setCompanieDetails", response);
        resolve();
      })
      .catch((error) => {
        reject(error);
        window.location.href = "/404";
      });
  });
};


